import request from "../../utils/request"


// 企业信息
export function userlist(sousuo, status, role, page, pagesize) {
    const url = `/api/user/list?sousuo=${sousuo}&status=${status}&role=${role}&page=${page}&pagesize=${pagesize}`;
    return request.get(url);
}


// 企业信息新增接口
export function userstore(enterprise, user_name, phone, user_names, phones, account, status, content) {
    const url = `/api/user/store`;
    return request.post(url, {
        enterprise,
        user_name,
        phone,
        user_names,
        phones,
        account,
        status,
        content
    });
}
// 企业信息修改接口
export function userupdate(role, enterprise, user_name, phone, user_names, phones, account, status, content, id) {
    const url = `/api/user/update`;
    return request.post(url, {
        role,
        enterprise,
        user_name,
        phone,
        user_names,
        phones,
        account,
        status,
        content,
        id
    });
}
// 更改状态接口
export function userstate(role, status, id) {
    const url = `/api/user/state`;
    return request.post(url, {
        role,
        status,
        id
    });
}

// 企业信息删除接口
export function userdel(ids, role) {
    const url = `/api/user/del`;
    return request.post(url, {
        ids,
        role
    })
}

// 重置密码
export function chongzhi(id) {
    const url = `/api/user/chongzhi`;
    return request.post(url, {
        id
    })
}