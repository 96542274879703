<template>
  <!-- 企业管理 -->
  <div class="user">
    <div class="usertop">
      <p>企业管理</p>
      <p>
        共<span>{{ total }}</span
        >家企业
      </p>
    </div>
    <div class="usermain">
      <div class="userbtn">
        <el-button type="success" @click="addshu">新增</el-button>
        <el-button type="danger" @click="delshu">删除</el-button>
        <div class="clas">
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible10"
            width="30%"
            :close-on-click-modal="false"
          >
            <span>该操作将会删除所选设备，请谨慎操作！</span>
            <el-input
              v-model="input10"
              placeholder="请输入密码进行确认"
              style="width: 60%; margin-top: 20px"
              type="password"
            ></el-input>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible10 = false">取 消</el-button>
              <el-button type="primary" @click="verydel">确 定</el-button>
            </span>
          </el-dialog>
        </div>
        <el-input
          v-model="userphone"
          placeholder="请输入企业名称/联系人姓名/联系电话"
          style="width: 400px; margin-left: 15px"
          @keyup.enter.native="chaxun"
        ></el-input>
        <p>状态：</p>
        <el-select v-model="value" placeholder="请选择" style="width: 100px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            style="font-size: 16px"
          >
          </el-option>
        </el-select>
        <el-button type="primary" style="margin-left: 15px" @click="chaxun"
          >查询</el-button
        >
      </div>
      <div class="userlist">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作" min-width="82%" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="selbtn">
                <p @click="bianji(scope.$index, scope.row)">编辑</p>
                <p @click="chognzhi(scope.$index, scope.row)">重置密码</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="企业信息"
            min-width="100%"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <p>{{ scope.row.enterprise }}</p>
              <p>{{ scope.row.enterprise_number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="联系人1" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.user_name }}</p>
              <p>{{ scope.row.phone }}</p>
            </template>
          </el-table-column>
          <el-table-column label="联系人2" show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.user_names }}</p>
              <p>{{ scope.row.phones }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="account" label="账号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="状态" min-width="100%" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#36c6d3"
                active-text="启用"
                inactive-text="关闭"
                :active-value="0"
                :inactive-value="1"
                style="font-size: 20px; height: 40px"
                @change="active_text($event, scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="备注"
            min-width="100%"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="创建时间"
            min-width="120%"
            show-overflow-tooltip
          >
          <!-- <template slot-scope="scope">
            {{scope.row.updated_at | dateFormat}}
          </template> -->
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="userpagging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage1"
            :page-sizes="[10, 15, 20]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            style="font-size: 16px"
          >
          </el-pagination>
        </div>
        <!-- 新增页面 -->
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="600px"
          :close-on-click-modal="false"
        >
          <div class="diabox">
            <p>企业名称：</p>
            <el-input
              v-model="newname"
              placeholder="请输入内容"
              style="width: 70%"
            ></el-input>
            <p class="another" v-if="msg != ''">
              {{ msg.enterprise == undefined ? "" : msg.enterprise[0] }}
            </p>
            <p>
              联系人1
              <span style="color: red"
                >(联系人1电话将用作接受设备报警信息，请务必正确填写：)</span
              >
            </p>
            <el-input
              v-model="name1"
              placeholder="请输入联系人姓名"
              style="width: 40%"
            >
              <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
            </el-input>
            <el-input
              v-model="phone1"
              placeholder="请输入联系人电话"
              style="width: 40%; margin-left: 10px"
            >
              <i slot="prefix" class="el-input__icon el-icon-phone"></i>
            </el-input>
            <p class="another" v-if="msg != ''">
              <span>{{
                msg.user_name == undefined ? "" : msg.user_name[0]
              }}</span>
              <span>{{ msg.phone == undefined ? "" : msg.phone[0] }}</span>
            </p>
            <p>联系人2：</p>
            <el-input
              v-model="name2"
              placeholder="请输入联系人姓名"
              style="width: 40%"
            >
              <i slot="prefix" class="el-input__icon el-icon-user-solid"></i
            ></el-input>
            <el-input
              v-model="phone2"
              placeholder="请输入联系人电话"
              style="width: 40%; margin-left: 10px"
            >
              <i slot="prefix" class="el-input__icon el-icon-phone"></i
            ></el-input>
            <p class="another" v-if="msg != ''">
              <span>{{
                msg.user_names == undefined ? "" : msg.user_names[0]
              }}</span>
              <span>{{ msg.phones == undefined ? "" : msg.phones[0] }}</span>
            </p>
            <p v-if="newdata">企业账号：</p>
            <el-input
              v-model="newzhang"
              placeholder="请输入内容"
              style="width: 70%"
              v-if="newdata"
            ></el-input>
            <p class="another" v-if="msg != ''">
              <span>{{ msg.account == undefined ? "" : msg.account[0] }}</span>
            </p>
            <p>状态：</p>
            <el-switch
              style="display: block; width: 130px"
              v-model="value2"
              active-text="启用"
              inactive-text="关闭"
              :active-value="0"
              :inactive-value="1"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <p>备注（选填）：</p>
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="textarea"
              maxlength="60"
              show-word-limit
              style="width: 70%"
            >
            </el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="aresure">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userlist,
  userstore,
  userupdate,
  userdel,
  userstate,
  chongzhi,
} from "../../api/user/store.js";
export default {
  inject:['reload'],//  此处引入在app中定义的reload方法
  components: {},
  props: {},
  data() {
    return {
      newdata: false,
      title: "新增企业",
      dialogVisible: false,
      dialogVisible10: false,
      msg: [],
      username: "",
      userphone: "",
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "启用",
        },
        {
          value: "2",
          label: "禁用",
        },
      ],
      value: "",
      tableData: [],
      // 这个是控制选择框的参数
      multipleSelection: [],
      currentPage1: 1,
      // 新增状态
      value2: "",
      // 备注
      textarea: "",
      // 企业账号
      newzhang: "",
      // 企业名称
      newname: "",
      name1: "",
      phone1: "",
      name2: "",
      phone2: "",
      // 一共多少条数据
      total: 0,
      chaphone: "",
      chavalue: "",
      meiye: "10",
      id: "",
      role: "1",
      input10: "",
    };
  },
  created() {
    console.log(this.$route.path);
    // console.log(this.$store.state.token);
    userlist("", "", "1", "1", "10").then((res) => {
      // console.log(res.data);
      var str = res.data;
      // this.tableData = res;
      this.total = str.total;
      this.tableData = str.data;
    });
  },
  mounted() {}, 
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 新增保存
    aresure() {
      console.log(this.value2);
      if (this.title == "新增企业") {
        if (this.phone1 == this.phone2 && this.phone1 != "") {
          this.msg = {
            phone: ["联系人一二的电话不能一样"],
            phones: ["联系人一二的电话不能一样"],
          };
          console.log(this.msg);
        } else {
          userstore(
            this.newname,
            this.name1,
            this.phone1,
            this.name2,
            this.phone2,
            this.newzhang,
            this.value2,
            this.textarea
          ).then((res) => {
            console.log(res);
            if (res.msg == "添加失败！") {
              this.msg = res.data;

              console.log(this.msg);
            } else {
              this.getlist(
                this.chaphone,
                this.chavalue,
                1,
                this.currentPage1,
                this.meiye
              );
              this.removeshuju();
            }
          });
        }
      } else if (this.title == "修改企业信息") {
        if (this.phone1 == this.phone2 && this.phone1 != "") {
          this.msg = {
            phone: ["联系人一二的电话不能一样"],
            phones: ["联系人一二的电话不能一样"],
          };
          console.log(this.msg);
        } else {
          userupdate(
            1,
            this.newname,
            this.name1,
            this.phone1,
            this.name2,
            this.phone2,
            this.newzhang,
            this.value2,
            this.textarea,
            this.id
          ).then((res) => {
            console.log(res);
            if (res.msg == "修改失败！") {
              this.msg = res.data;
              console.log("1321465465");
            } else {
              this.getlist(
                this.chaphone,
                this.chavalue,
                1,
                this.currentPage1,
                this.meiye
              );
              this.removeshuju();
            }
          });
        }
      }
    },
    // 新增
    addshu() {
      this.removeshuju();
      this.dialogVisible = true;
      this.value2 = 1;
      this.newdata = true;
    },
    // 删除数据
    verydel() {
      if (this.input10 === this.$store.state.password) {
        let str = this.multipleSelection;
        let attr = [];
        for (var i = 0; i < str.length; i++) {
          attr.push(str[i].id);
        }
        this.currentPage1 = 1;
        userdel(attr).then((res) => {
          if (res.msg == "删除成功！") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getlist(
              this.chaphone,
              this.chavalue,
              1,
              this.currentPage1,
              this.meiye
            );
            this.input10 = ""
            this.dialogVisible10 = false;
          }
          if (res.msg == "该企业下设备未删除，请删除后再次操作！") {
            this.$message.error("该企业下设备未删除，请删除后再次操作！");
            this.dialogVisible10 = false;
          }
        });
      } else {
        if (this.input10 === "") {
          this.$message.error("请输入密码！");
        } else {
          this.$message.error("密码错误！");
        }
      }
    },
    delshu() {
      if (this.multipleSelection.length > 0) {
        this.dialogVisible10 = true;
      }
    },
    // 清除数据
    removeshuju() {
      this.dialogVisible = false;
      // 新增状态
      this.value2 = "";
      // 备注
      this.textarea = "";
      // 企业账号
      this.newzhang = "";
      // 企业名称
      this.newname = "";
      this.name1 = "";
      this.phone1 = "";
      this.name2 = "";
      this.phone2 = "";
      this.msg = "";
      this.id = "";
    },
    // 编辑
    bianji(index, row) {
      console.log(index);
      this.removeshuju();
      this.title = "修改企业信息";
      this.dialogVisible = true;
      this.newdata = false;
      // 新增状态
      this.value2 = row.status;
      // 备注
      this.textarea = row.content;
      // 企业账号
      this.newzhang = row.account;
      // 企业名称
      this.newname = row.enterprise;
      this.name1 = row.user_name;
      this.phone1 = row.phone;
      this.name2 = row.user_names;
      this.phone2 = row.phones;
      this.id = row.id;
    },
    // 重置密码
    chognzhi(index, row) {
      console.log(index);
      console.log(row);
      this.$confirm("此操作将重置密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          chongzhi(row.id).then((res) => {
            if (res.msg == "密码重置成功！") {
              this.$message({
                type: "success",
                message: "重置成功!",
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    active_text(value, row) {
      console.log(value, row);
      userstate(1, value, row.id).then((res) => {
        console.log(res);
      });
    },
    handleSizeChange(val) {
      this.meiye = val;
      this.currentPage1 = 1;
      this.getlist(
        this.chaphone,
        this.chavalue,
        1,
        this.currentPage1,
        this.meiye
      );
    },
    handleCurrentChange(val) {
      this.currentPage1 = val;
      this.getlist(
        this.chaphone,
        this.chavalue,
        1,
        this.currentPage1,
        this.meiye
      );
    },
    // 查询按钮
    chaxun() {
      this.chaphone = JSON.parse(JSON.stringify(this.userphone));
      if (this.value == 0) {
        this.chavalue = 0;
        this.getlist(this.userphone, 0, 1, 1, 10);
      } else if (this.value == 2) {
        this.chavalue = 2;
        this.getlist(this.userphone, 2, 1, 1, 10);
      } else if (this.value == 1) {
        this.chavalue = 1;
        this.getlist(this.userphone, 1, 1, 1, 10);
      }
    },
    //数据展示接口方法
    getlist(sousuo, status, role, page, pagesize) {
      userlist(sousuo, status, role, page, pagesize).then((res) => {
        console.log(res.data);
        var str = res.data;
        // this.tableData = res;
        this.total = str.total;
        this.tableData = str.data;
      });
    },
  },
};
</script>

<style  lang='less' scoped>
.user {
  .another {
    width: 90%;
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    color: red;
    span {
      display: inline-block;
      width: 50%;
    }
  }
  padding: 20px;
  .usertop {
    p {
      margin: 0px;
    }
    height: 67px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .usermain {
    min-height: 760px;
    background: white;
    padding: 35px;
    padding-bottom: 15px;
    font-size: 20px;
    .userbtn {
      display: flex;
      align-items: center;
      p {
        margin-left: 15px;
        font-size: 24px;
      }
      /deep/.el-button--success {
        font-size: 24px;
      }
      /deep/.el-button--danger {
        font-size: 24px;
      }
      /deep/.el-input__inner {
        font-size: 18px;
      }
      /deep/.el-select-dropdown__item {
        font-size: 20px;
      }
      /deep/.el-button--primary {
        font-size: 24px;
      }
    }
    .userlist {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: normal;
        word-break: break-all;
      }
      /deep/.el-table th,
      .el-table tr {
        font-size: 24px !important;
      }
      /deep/.el-table__body,
      .el-table__footer,
      .el-table__header {
        font-size: 20px;
      }
      /deep/.el-switch__label * {
        font-size: 20px;
      }
      .selbtn {
        display: flex;
        p {
          margin-right: 10px;
          color: #5c89f4;
          cursor: pointer;
        }
      }
    }
    .userpagging {
      margin-top: 20px;
    }
  }
  /deep/.el-table th>.cell {
    height: 40px;
    position: relative;
    vertical-align: middle;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    width: 100%;
}
  /deep/.el-table th,
  .el-table tr {
    font-size: 20px !important;
    height: 70px;
    background: #f2f2f2;
  }
  /deep/.el-table__body,
  .el-table__footer,
  .el-table__header {
    font-size: 20px !important;
  }
  /deep/.el-switch__label * {
    font-size: 16px;
  }
  /deep/.el-pagination__total {
    font-size: 20px !important;
  }
  /deep/.el-pager li {
    font-size: 17px;
  }
  /deep/.el-pagination__jump {
    font-size: 20px !important;
  }
  /deep/.el-input--suffix .el-input__inner {
    font-size: 18px !important;
  }
  /deep/.el-dialog__title {
    font-size: 24px;
  }
  /deep/.el-dialog__body {
    margin-top: -20px;
  }
  .diabox {
    font-size: 20px;
    /deep/.el-switch {
      width: 100% !important;
    }
    /deep/.el-input__inner {
      font-size: 20px !important;
    }
    /deep/.el-textarea__inner {
      font-size: 20px !important;
    }
  }
  .clas {
    /deep/.el-dialog__body {
      span {
        font-size: 20px;
      }
    }
    /deep/.el-button {
      font-size: 16px !important;
    }
  }
}
</style>
